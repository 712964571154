/*
 * @Author: zhouxingtong
 * @Email: 495144492@qq.com
 * @Date: 2024-09-20 16:56:33
 * @LastEditors: zhouxingtong
 * @LastEditTime: 2024-10-14 14:33:34
 * @Description: 
 */
import React, {useEffect, useState} from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { ConfigProvider, Message } from "@arco-design/web-react";
import zhCN from "@arco-design/web-react/es/locale/zh-CN";
import enUS from "@arco-design/web-react/es/locale/en-US";
import ReactDOM from "react-dom";
import { Route, Router, Switch } from "react-router-dom";
import rootReducer from "./redux";
import history from "./history";
import PageLayout from "./layout/page-layout";
import Setting from "./components/Settings";
import {GlobalContext} from "./context";
import "./style/index.less";
import Login from "./pages/login";
import checkLogin from "./utils/checkLogin";
import { api } from "./redux/api";
import { initVChartArcoTheme } from '@visactor/vchart-arco-theme';
initVChartArcoTheme({
  defaultMode:'dark',
  isWatchingMode:true,
  prefix:'--color-data-1'
});
declare global {
  interface Window {
    h5sdk: any;
  }
};
const store = createStore(rootReducer);

function Index() {
  const localeName = localStorage.getItem('arco-lang') || 'zh-CN';

  if (!localStorage.getItem('arco-lang')) {
    localStorage.setItem('arco-lang', localeName);
  }

  const [locale, setLocale] = useState();

  async function fetchLocale(ln?: string) {
    const locale = (await import(`./locale/${ln || localeName}`)).default;
    setLocale(locale);
  }

  function getArcoLocale() {
    switch (localeName) {
      case 'zh-CN':
        return zhCN;
      case 'en-US':
        return enUS;
      default:
        return zhCN;
    }
  }

  function fetchUserInfo(token = "") {
    token && api.checkLogin().then(res => {
      // @ts-ignore
      if (res && res.code === 0) {} 
      else {
        localStorage.removeItem("token")
        history.push("/login");
      }
    })
  }

  useEffect(() => {
    if (!window.h5sdk) fetchLocale()
  }, []);

  useEffect(() => {
    Message.loading("加载中...");
    
    setTimeout(() => {
      if (window.h5sdk) {
        // 通过ready接口确认环境准备就绪后才能调用API
        const code = localStorage.getItem('code');
        if (code) {
          api.loginFeiShu({ code }).then(res => {
            if (res.code === 0) {
              // 登录成功
              localStorage.removeItem('code');
              localStorage.setItem('token', res.data.token);
              localStorage.setItem('user', JSON.stringify(res.data.user));
              
              fetchLocale();
            }
          });
        }
      } else {
        let token = checkLogin();
        if (token) {
          fetchUserInfo(token);
        } else {
          history.push('/login');
        }
      };

      Message.clear();
    }, 1000);
  }, []);

  const contextValue = {
    locale,
  };
  return locale ? (
    <Router history={history}>
      <ConfigProvider locale={getArcoLocale()}>
        <Provider store={store}>
          <GlobalContext.Provider value={contextValue}>
            <Switch>
              <Route path="/login" component={Login}/>
              <Route path="/" component={PageLayout}/>
            </Switch>
            <Setting/>
          </GlobalContext.Provider>
        </Provider>
      </ConfigProvider>
    </Router>
  ) : null;
}

ReactDOM.render(<Index/>, document.getElementById('root'));
